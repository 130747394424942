<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">REGISTER TELLER FUND</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              :items="teller_items"
              item-value="id"
              item-text="name"
              label="Tellers"
              required
              v-model="teller"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col
            md="12"
            cols="12"
          >
            <v-text-field
              v-model="fund_amount"
              type="number"
              label="Fund Amount"
              dense
              outlined
              :min="1"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-col>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12" v-if="branch_id!=1">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <v-card
      flat
    >
      <v-toolbar-title><h4 class="font-weight-light mx-2">TELLER FUND DATA HISTORY</h4>
      </v-toolbar-title>
      <v-data-table dense
                    :headers="headers_teller"
                    :items="teller_fund_datas">
        <template v-slot:item="{ item }">
          <tr
          >
            <td>{{ formatPrice(item.fund) }}</td>
            <td>
              {{ formatPrice(item.cash_on_hand) }}
            </td>
            <td>
              {{ item.date }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
        teller_fund_datas: [],
        headers_teller: [
          {text: 'FUND', value: 'id', sortable: false},
          {text: 'COH', value: 'date_of_deposit', sortable: false},
          {text: 'DATE', value: 'amount', sortable: false},],
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.teller_fund_data(data)
        .then(response => {
          this.teller_fund_datas = response.data
        })
        .catch(error => {
          console.log(error)
        })
      if (this.branch_id != 1) {
        this.teller = ''
        this.list_of_teller_active(data)
          .then(response => {
            this.teller_items = response.data
            this.teller_items.splice(0, 0, {
              id: 0,
              name: 'All',
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        fund_amount: '',

        teller_items: [],
        teller: '',
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['list_of_teller_active']),
      ...mapActions('teller_fund', ['register_teller_fund','teller_fund_data']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.teller = ''
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var index = this.teller_items.map(function (x) {
            return x.id;
          }).indexOf(this.teller)
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('date', moment().format('MMMM DD, YYYY'));
          data.append('teller_id', this.teller_items[index].id);
          data.append('fund', this.fund_amount);
          this.register_teller_fund(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
